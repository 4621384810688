import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { getComponent } from "../components/StructuredContentRenderer/StructuredContentRenderer"

const MissingComponentsPage = ({ data }) => {
  const { allStructuredContentFields: result } = data
  const { nodes } = result

  const entries = Array.from(
    nodes.reduce((acc, current) => {
      if (current?.structuredContentFields) {
        current.structuredContentFields.forEach(x => acc.add(x))
      }
      return acc
    }, new Set())
  ).filter(component => !getComponent(component))

  if (entries.length === 0) {
    // NOTE: text is used by notification: https://github.com/fundera/nw-smb-gatsby/blob/55817a8235f019e5f2451217fc2a4a3f59310b67/.github/workflows/prod-nw-smb.yml#L183
    return <div>No missing components</div>
  }

  return (
    <div>
      <h1>Missing components:</h1>
      <pre>
        {JSON.stringify(
          { components: entries, total: entries.length },
          null,
          2
        )}
      </pre>
    </div>
  )
}

MissingComponentsPage.propTypes = {
  data: PropTypes.shape({
    allStructuredContentFields: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          structuredContentFields: PropTypes.arrayOf(PropTypes.string)
            .isRequired,
        })
      ).isRequired,
    }),
  }),
}

export default MissingComponentsPage

export const query = graphql`
  query StructuredContentQuery {
    allStructuredContentFields {
      nodes {
        structuredContentFields
      }
    }
  }
`
